export const salesChannelOptions = [
  {
    label: 'retail',
    value: 'retail',
  },
  {
    label: 'ecommerce',
    value: 'ecommerce',
  },
];

export const MAX_PRODUCT_NAME_LENGTH = 1000;
export const MAX_SKU_NUMBER_LENGTH = 60;
export const MAX_UPC_NUMBER_LENGTH = 12;

export const fragranceOptions = [
  {
    label: 'Has fragrance',
    value: true,
  },
  {
    label: 'Does not have fragrance',
    value: false,
  },
];

export const flavorOptions = [
  {
    label: 'Has flavor',
    value: true,
  },
  {
    label: 'Does not have flavor',
    value: false,
  },
];

export const skuPayloadParametersFromForm = {
  1: [
    // common
    'product_category_id',
    'retailer_sales_channel',
    'sku_id',
    'product_line',
    'product_name',
    'upc_number',
    'product_type_id',
  ],
  2: [
    // common
    'product_category_id',
    'retailer_sales_channel',
    'sku_id',
    'product_line',
    'product_name',
    'upc_number',
    'product_type_id',
  ],
};

export const formulationPayloadParametersFromForm = {
  1: [
    'application_time',
    'product_use_ids',
    'product_form_id',
    'fragrance',
    'flavor',
    // common
    'product_claim_ids',
  ],
  2: [
    // common
    'product_claim_ids',
  ],
};

export const requiredFormFields = {
  1: [
    'application_time',
    'product_use_ids',
    'product_form_id',
    'fragrance',
    'flavor',
    // common
    'product_name',
    'product_type_id',
  ],
  2: [
    // common
    'product_name',
    'product_type_id',
  ],
};

export const productTypeFormalNameMap = {
  1: 'Beauty and Personal Care',
  2: 'Food',
};
