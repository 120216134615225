import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  ChangeEvent,
  LegacyRef,
} from 'react';
import cn from 'classnames';
import {
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { InputSize } from './constants';
import styles from './input-v2.module.scss';
import { Color } from 'design-system/data';

export type InputV2Props = {
  id?: string;
  name: string;
  value: string;
  type?: string;
  leadChildren?: any; //needs to be typed (react node?)
  leadIconName?: string;
  endIconName?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  size?: string; //make oneOf InputSize(needs to be typed)
  hasError: boolean;
  errorMessage: string;
  handleInputChange: (event: ChangeEvent<any>) => void;
};

const InputV2 = forwardRef(
  (
    {
      id,
      name,
      leadChildren,
      leadIconName,
      endIconName,
      label,
      required,
      placeholder,
      size = InputSize.Small,
      hasError = false,
      errorMessage,
      handleInputChange,
      ...rest
    }: InputV2Props,
    ref
  ) => {
    const leadIconRef = useRef<any>(null);
    const endIconRef = useRef<any>(null);
    const leadChildrenRef = useRef<any>(null);
    const [inputDynamicStyles, setInputDynamicStyles] = useState({
      paddingLeft: 14,
      paddingRight: 14,
    });

    useEffect(() => {
      const newInputDynamicStyles = { paddingLeft: 14, paddingRight: 14 };
      if (leadIconRef.current)
        newInputDynamicStyles.paddingLeft +=
          leadIconRef.current.offsetWidth + 8;
      if (leadChildrenRef.current)
        newInputDynamicStyles.paddingLeft +=
          leadChildrenRef.current.offsetWidth + 8;
      if (endIconRef.current)
        newInputDynamicStyles.paddingRight += endIconRef.current.offsetWidth;
      setInputDynamicStyles(newInputDynamicStyles);
    }, [leadIconRef, endIconRef, leadChildrenRef, leadChildren]);

    return (
      <>
        <label htmlFor={id}>
          <Text
            kind={TextKind.TextSMMedium}
            element={TextElement.P}
            color={Color.Neutral700}
          >
            {label}
            {required && <span className={styles.required}>*</span>}
          </Text>
        </label>
        <div className={cn([styles[size], styles.root])}>
          <div className={styles['lead-wrapper']}>
            {leadIconName && (
              <div className={styles['lead-icon']} ref={leadIconRef}>
                <PhosphorIcon iconName={leadIconName} size={18} />
              </div>
            )}
            {leadChildren && (
              <div
                ref={leadChildrenRef}
                className={styles['lead-children-wrapper']}
              >
                {leadChildren}
              </div>
            )}
          </div>
          <input
            ref={ref as LegacyRef<HTMLInputElement>}
            style={inputDynamicStyles}
            onChange={handleInputChange}
            className={`${styles.input} ${hasError ? styles.error : ''}`}
            name={name}
            {...rest}
          />
          {hasError && (
            <Text
              color={Color.Red600}
              element={TextElement.Label}
              kind={TextKind.TextSM}
            >
              {errorMessage}
            </Text>
          )}
          <div ref={endIconRef} className={styles['end-icon']}>
            {endIconName &&
              (hasError ? (
                <span className={styles['error-icon']}>
                  <PhosphorIcon
                    iconName={endIconName}
                    color={Color.Red600.value}
                    weight={PhosphorIconWeight.Bold}
                    size={16}
                  />
                </span>
              ) : (
                <PhosphorIcon iconName={endIconName} size={18} />
              ))}
          </div>
        </div>
      </>
    );
  }
);

export default InputV2;
